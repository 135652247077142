import React from "react"


const Door = ({ AudioFile }) => {
  
  const muteButton = React.useRef();

  const [doorPosition, setDoorPosition] = React.useState("close");
  const openButton = () => {
    setDoorPosition("opening");
    setTimeout(() => {
      setDoorPosition("open");
    }, 5000);
  };
  
  const audio = React.useState(typeof Audio !== "undefined" && new Audio(AudioFile));
  audio.loop = true;
  const onPlay = () => audio.play();

  const audioMute = () => {
    if(audio.muted === false) {
      audio.muted = true;
      muteButton.current.classList.add("Mute_Active");
    } else {
      audio.muted = false;
      muteButton.current.classList.remove("Mute_Active");
    }
  };

  /* eslint-disable */
  React.useEffect(() => {
    if (doorPosition !== "open") {
      document.body.style.overflow = 'hidden';
    }
    if (doorPosition === "open") {
      audio.addEventListener("canplaythrough", onPlay);
    }
    return () => {
      document.body.style.overflow = 'unset';
      audio.pause();
      audio.removeEventListener("canplaythrough", onPlay);
    };
  }, [doorPosition, AudioFile]);
  /* eslint-enable */

	return(
    <>
    <div className={`Door_Container ${doorPosition === "open" ? "Door_Container_Remove" : ""}`}>
      <div className={`Door_Main ${doorPosition === "opening" ? "Door_Main_Active" : ""}`}>
      <div className="Door_Main_Left"/>
      <div className="Door_Main_Right">
        <button className={`Door_Main_Button ${doorPosition === "opening" ? "Door_Main_Button_Close" : ""}`} onClick={openButton} onKeyDown={openButton}>Open</button>
      </div>
      </div>
    </div>
    {doorPosition === "open" &&
    <div className="Mute_Button_Container" onClick={audioMute} onKeyDown={audioMute} role="button" tabIndex={0}>
      <svg ref={muteButton} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="Mute_Button">
        <path d="M633.82 458.1l-69-53.33C592.42 360.8 608 309.68 608 256c0-95.33-47.73-183.58-127.65-236.03-11.17-7.33-26.18-4.24-33.51 6.95-7.34 11.17-4.22 26.18 6.95 33.51 66.27 43.49 105.82 116.6 105.82 195.58 0 42.78-11.96 83.59-33.22 119.06l-38.12-29.46C503.49 318.68 512 288.06 512 256c0-63.09-32.06-122.09-85.77-156.16-11.19-7.09-26.03-3.8-33.12 7.41-7.09 11.2-3.78 26.03 7.41 33.13C440.27 165.59 464 209.44 464 256c0 21.21-5.03 41.57-14.2 59.88l-39.56-30.58c3.38-9.35 5.76-19.07 5.76-29.3 0-31.88-17.53-61.33-45.77-76.88-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61 11.76 6.46 19.12 18.18 20.4 31.06L288 190.82V88.02c0-21.46-25.96-31.98-40.97-16.97l-49.71 49.7L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.41-6.97 4.16-17.02-2.82-22.45zM32 184v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V352.6L43.76 163.84C36.86 168.05 32 175.32 32 184z" />
      </svg>
    </div>
    }
    </>
  );
};

export default Door